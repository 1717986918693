import { AbstractConnectorArguments, ConnectorUpdate } from '@web3-react/types'
import { AbstractConnector } from '@web3-react/abstract-connector'
import ERC20_ABI from '../constants/abis/erc20.json'
import ROUTE_ABI from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { BigNumber } from 'ethers'
const MCP = require('mcp.js')
const Web3 = require('web3')
const NETWORK_URL = process.env.REACT_APP_NETWORK_URL

export class NoEthereumProviderError extends Error {
  public constructor() {
    super()
    this.name = this.constructor.name
    this.message = 'No Ethereum provider was found on window.ethereum.'
  }
}

export class UserRejectedRequestError extends Error {
  public constructor() {
    super()
    this.name = this.constructor.name
    this.message = 'The user rejected the request.'
  }
}

export class Aleconnector extends AbstractConnector {
  private active: boolean

  constructor(kwargs: AbstractConnectorArguments) {
    super(kwargs)
    this.handleNetworkChanged = this.handleNetworkChanged.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
    this.active = false
  }

  private async handleAccountsChanged(accounts: string) {
    if (accounts === '') {
      this.emitDeactivate()
    } else {
      this.emitUpdate({ account: accounts })
    }
  }

  private async handleNetworkChanged(networkId: string | number) {
    if (networkId == 3) {
      networkId = 2
    }
    const provider = (window as any).aleereum
    if (!provider.isConnected || provider.islocked) await provider.connect()
    this.emitUpdate({ chainId: networkId, provider: new CustomProvider() })
    this.active = true
  }

  async activate(): Promise<ConnectorUpdate<string | number>> {
    const provider = (window as any).aleereum
    if (!provider) {
      throw new NoEthereumProviderError()
    }
    provider.on('on_account_change', this.handleAccountsChanged)
    provider.on('on_networkId_change', this.handleNetworkChanged)
    if (!provider.isConnected || provider.islocked) await provider.connect()
    var chainId = -1
    if(provider.networkId==3){
      chainId = 2
    }
    const account = provider.account
    return { provider: new CustomProvider(), chainId: chainId, ...(account ? { account } : {}) }
  }
  getProvider(): Promise<any> {
    const provider = (window as any).aleereum
    return provider
  }
  getChainId(): Promise<string | number> {
    const provider = (window as any).aleereum
    let networkId = provider.networkId
    if (networkId == 3) {
      networkId = 2
    }
    return networkId
  }
  async getAccount(): Promise<string | null> {
    const provider = (window as any).aleereum
    const account = provider.account
    if (account && !this.active) {
      this.active = true
      if (!provider.isConnected || provider.islocked) await provider.connect()
      this.emitUpdate({ chainId: 2, provider: new CustomProvider(), account })
    }
    return account
  }
  deactivate(): void {
    const provider = (window as any).aleereum
    if (provider && provider.removeListener) {
      provider.removeListener('on_networkId_change', this.handleAccountsChanged)
      provider.removeListener('on_account_change', this.handleNetworkChanged)
    }
  }
  public async isAuthorized(): Promise<boolean> {
    return false
  }
}

class CustomProvider {
  isMetaMask = false
  host = ''
  path = ''
  web3: any
  mcp: any

  constructor() {
    this.web3 = new Web3(NETWORK_URL)
    this.mcp = new MCP()
    this.mcp.Contract.setProvider(NETWORK_URL)
  }

  sendAsync(request: any, callback: any): void {
    // console.log("------ sendAsync")
    // console.log(JSON.stringify(request));
  }
  send(request: any, callback: any): void {
    // console.log("------ send")
    // console.log(JSON.stringify(request));
  }

  fetchRequest(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(NETWORK_URL as string, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(params)
      })
        .then(response => response.json())
        .then(data => {
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async request(request: any, params: Array<any>): Promise<any> {
    // console.log("------ request")
    // console.log(JSON.stringify(request));
    const root = this
    if (request.method === 'eth_chainId') {
      return new Promise((resolve, reject) => {
        resolve('0x2')
      })
    } else if (request.method === 'net_version') {
      return new Promise((resolve, reject) => {
        resolve('0x2')
      })
    } else if (request.method === 'eth_blockNumber') {
      return new Promise((resolve, reject) => {
        root.fetchRequest({ action: 'status' }).then(r => {
          resolve(r.last_stable_mci)
        })
      })
    } else if (request.method === 'eth_call') {
      const rp = request.params
      if (rp && rp.length > 0) {
        const item = rp[0]
        const to = item.to
        const data = item.data
        return new Promise((resolve, reject) => {
          root.fetchRequest({ action: 'call', to, data }).then(r => {
            resolve('0x' + r.output)
          })
        })
      }
    } else if (request.method === 'eth_estimateGas') {
      const rp = request.params
      if (rp && rp.length > 0) {
        const item = rp[0]
        const to = item.to
        const from = item.from
        const data = item.data
        return new Promise((resolve, reject) => {
          root.fetchRequest({ action: 'estimate_gas', to, from, data }).then(r => {
            resolve(r.gas)
          })
        })
      }
    } else if (request.method === 'eth_getTransactionReceipt') {
      const rp = request.params
      if (rp && rp.length > 0) {
        return new Promise((resolve, reject) => {
          root.fetchRequest({ jsonrpc: '2.0', method: 'eth_getTransactionReceipt', params: [rp[0]], id: 1 }).then(r => {
            //console.log('----- '+rp[0]+"\n"+JSON.stringify(r));
            if (r.error) {
              resolve({
                blockHash: '0x1d0162c93a83e5519de6602aa88ea415d78467e59dea74925a10967d96075d3f',
                blockNumber: '0x3b592',
                from: '0xa0a9c9a902c53947a89291c86422e027e1f07112',
                to: null,
                contractAddress: '0x72f2746f7e3ac76038ad7dc79058819b2ddf75c9',
                gasUsed: '0x1f3cd',
                cumulativeGasUsed: '0x23139',
                gasPrice: '0x3b9aca00',
                transactionHash: rp[0],
                logs: [],
                logsBloom:
                  '0x00200000000000400000100080000000000000000000000000000000000000000000000000000000000000000000000000020000000000200004000002000000000080000200000200000009000000200000000000000000000000000000000000000000000000040000000008000000000000000000000000000010000800000000000000400000000000004000000000000000000000880000004000000000000000000000000000000000000000200000000000000000000000000000000000000002000000000000000000000000000000000000001000000000000000000000080000000000000000000000000000000000000000000000000000000000',
                transactionIndex: '0x0',
                status: '0x2'
              })
            } else {
              resolve(r.result)
            }
          })
        })
      }
    } else if (request.method === 'eth_sendTransaction') {
      const rp = request.params
      if (rp && rp.length > 0) {
        const item = rp[0]
        const to = item.to
        const from = item.from
        const data = item.data
        var value = "0"
        if(item.value){
          value = BigNumber.from(item.value).toString()
        }
        if (data) {
          const approve = root.web3.eth.abi.encodeFunctionSignature('approve(address,uint256)')
          if (data.startsWith(approve)) {
            const params = data.replace(approve, '0x')
            const result = root.web3.eth.abi.decodeParameters(['address', 'uint256'], params)
            const Contract = new root.mcp.Contract(ERC20_ABI, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .approve(result[0], result[1])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const addLiquidity = root.web3.eth.abi.encodeFunctionSignature(
            'addLiquidity(address,address,uint256,uint256,uint256,uint256,address,uint256)'
          )
          if (data.startsWith(addLiquidity)) {
            const params = data.replace(addLiquidity, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'address', 'uint256', 'uint256', 'uint256', 'uint256', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .addLiquidity(result[0], result[1], result[2], result[3], result[4], result[5], result[6], result[7])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const addLiquidityETH = root.web3.eth.abi.encodeFunctionSignature(
            'addLiquidityETH(address,uint256,uint256,uint256,address,uint256)'
          )
          if (data.startsWith(addLiquidityETH)) {
            const params = data.replace(addLiquidityETH, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'uint256', 'uint256', 'uint256', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .addLiquidityETH(result[0], result[1], result[2], result[3], result[4], result[5])
                .sendToBlock({
                  from: from,
                  amount: value
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }

          const swapExactTokensForTokens = root.web3.eth.abi.encodeFunctionSignature(
            'swapExactTokensForTokens(uint256,uint256,address[],address,uint256)'
          )
          if (data.startsWith(swapExactTokensForTokens)) {
            const params = data.replace(swapExactTokensForTokens, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['uint256', 'uint256', 'address[]', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            //console.log(JSON.stringify(result))
            return new Promise((resolve, reject) => {
              Contract.methods
                .swapExactTokensForTokens(result[0], result[1], result[2], result[3], result[4])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  // console.log(JSON.stringify(res));
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }

          const swapTokensForExactTokens = root.web3.eth.abi.encodeFunctionSignature(
            'swapExactTokensForTokens(uint256,uint256,address[],address,uint256)'
          )
          if (data.startsWith(swapTokensForExactTokens)) {
            const params = data.replace(swapTokensForExactTokens, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['uint256', 'uint256', 'address[]', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .swapTokensForExactTokens(result[0], result[1], result[2], result[3], result[4])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }

          const swapExactETHForTokens = root.web3.eth.abi.encodeFunctionSignature(
            'swapExactETHForTokens(uint256,address[],address,uint256)'
          )
          if (data.startsWith(swapExactETHForTokens)) {
            const params = data.replace(swapExactETHForTokens, '0x')
            const result = root.web3.eth.abi.decodeParameters(['uint256', 'address[]', 'address', 'uint256'], params)
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .swapExactETHForTokens(result[0], result[1], result[2], result[3])
                .sendToBlock({
                  from: from,
                  amount: value
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }

          const swapTokensForExactETH = root.web3.eth.abi.encodeFunctionSignature(
            'swapTokensForExactETH(uint256,uint256,address[],address,uint256)'
          )
          if (data.startsWith(swapTokensForExactETH)) {
            const params = data.replace(swapTokensForExactETH, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['uint256', 'uint256', 'address[]', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .swapTokensForExactETH(result[0], result[1], result[2], result[3], result[4])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }

          const swapExactTokensForETH = root.web3.eth.abi.encodeFunctionSignature(
            'swapExactTokensForETH(uint256,uint256,address[],address,uint256)'
          )
          if (data.startsWith(swapExactTokensForETH)) {
            const params = data.replace(swapExactTokensForETH, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['uint256', 'uint256', 'address[]', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .swapExactTokensForETH(result[0], result[1], result[2], result[3], result[4])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }

          const swapETHForExactTokens = root.web3.eth.abi.encodeFunctionSignature(
            'swapETHForExactTokens(uint256,address[],address,uint256)'
          )
          if (data.startsWith(swapETHForExactTokens)) {
            const params = data.replace(swapETHForExactTokens, '0x')
            const result = root.web3.eth.abi.decodeParameters(['uint256', 'address[]', 'address', 'uint256'], params)
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .swapETHForExactTokens(result[0], result[1], result[2], result[3])
                .sendToBlock({
                  from: from,
                  amount: value
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const removeLiquidity = root.web3.eth.abi.encodeFunctionSignature(
            'removeLiquidity(address,address,uint256,uint256,uint256,address,uint256)'
          )
          if (data.startsWith(removeLiquidity)) {
            const params = data.replace(removeLiquidity, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'address', 'uint256', 'uint256', 'uint256', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .removeLiquidity(result[0], result[1], result[2], result[3], result[4], result[5], result[6])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const removeLiquidityETH = root.web3.eth.abi.encodeFunctionSignature(
            'removeLiquidityETH(address,uint256,uint256,uint256,address,uint256)'
          )
          if (data.startsWith(removeLiquidityETH)) {
            const params = data.replace(removeLiquidityETH, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'uint256', 'uint256', 'uint256', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .removeLiquidityETH(result[0], result[1], result[2], result[3], result[4], result[5])
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const removeLiquidityWithPermit = root.web3.eth.abi.encodeFunctionSignature(
            'removeLiquidityWithPermit(address,address,uint256,uint256,uint256,address,uint256,bool,uint8,bytes32,bytes32)'
          )
          if (data.startsWith(removeLiquidityWithPermit)) {
            const params = data.replace(removeLiquidityWithPermit, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              [
                'address',
                'address',
                'uint256',
                'uint256',
                'uint256',
                'address',
                'uint256',
                'bool',
                'uint8',
                'bytes32',
                'bytes32'
              ],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .removeLiquidityWithPermit(
                  result[0],
                  result[1],
                  result[2],
                  result[3],
                  result[4],
                  result[5],
                  result[6],
                  result[7],
                  result[8],
                  result[9],
                  result[10]
                )
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const removeLiquidityETHWithPermit = root.web3.eth.abi.encodeFunctionSignature(
            'removeLiquidityETHWithPermit(address,uint256,uint256,uint256,address,uint256,bool,uint8,bytes32,bytes32)'
          )
          if (data.startsWith(removeLiquidityETHWithPermit)) {
            const params = data.replace(removeLiquidityETHWithPermit, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'uint256', 'uint256', 'uint256', 'address', 'uint256', 'bool', 'uint8', 'bytes32', 'bytes32'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .removeLiquidityETHWithPermit(
                  result[0],
                  result[1],
                  result[2],
                  result[3],
                  result[4],
                  result[5],
                  result[6],
                  result[7],
                  result[8],
                  result[9]
                )
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const removeLiquidityETHSupportingFeeOnTransferTokens = root.web3.eth.abi.encodeFunctionSignature(
            'removeLiquidityETHSupportingFeeOnTransferTokens(address,uint256,uint256,uint256,address,uint256)'
          )
          if (data.startsWith(removeLiquidityETHSupportingFeeOnTransferTokens)) {
            const params = data.replace(removeLiquidityETHSupportingFeeOnTransferTokens, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'uint256', 'uint256', 'uint256', 'address', 'uint256'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .removeLiquidityETHSupportingFeeOnTransferTokens(
                  result[0],
                  result[1],
                  result[2],
                  result[3],
                  result[4],
                  result[5]
                )
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
          const removeLiquidityETHWithPermitSupportingFeeOnTransferTokens = root.web3.eth.abi.encodeFunctionSignature(
            'removeLiquidityETHWithPermitSupportingFeeOnTransferTokens(address,uint256,uint256,uint256,address,uint256,bool,uint8,bytes32,bytes32)'
          )
          if (data.startsWith(removeLiquidityETHWithPermitSupportingFeeOnTransferTokens)) {
            const params = data.replace(removeLiquidityETHWithPermitSupportingFeeOnTransferTokens, '0x')
            const result = root.web3.eth.abi.decodeParameters(
              ['address', 'uint256', 'uint256', 'uint256', 'address', 'uint256', 'bool', 'uint8', 'bytes32', 'bytes32'],
              params
            )
            const Contract = new root.mcp.Contract(ROUTE_ABI.abi, to)
            return new Promise((resolve, reject) => {
              Contract.methods
                .removeLiquidityETHWithPermitSupportingFeeOnTransferTokens(
                  result[0],
                  result[1],
                  result[2],
                  result[3],
                  result[4],
                  result[5],
                  result[6],
                  result[7],
                  result[8],
                  result[9]
                )
                .sendToBlock({
                  from: from,
                  amount: '0'
                })
                .then((res: any) => {
                  if (res && res.success) {
                    resolve('0x' + res.msg)
                  } else {
                    reject('error')
                  }
                })
            })
          }
        }
      }
    } else {
      return null
    }
  }
}
